export const stringIsSearchable = (searchQuery) => {
    // Long anough and contans non-numeric chars
    const minChars = 3

    if (searchQuery.length <= minChars) {
        return false
    }

    if (!((typeof searchQuery) === 'string')) {
        return false
    }

    if (!(isNaN(searchQuery))) {
        return false
    }

    return true;
}