import Plausible from 'plausible-tracker'

export const init_plausible = () => {

    const reporting_domain = () => {
        const hostname = window.location.hostname;
    
        if (hostname === undefined)
            return undefined;
    
        if (hostname.indexOf('shop.phasst.com') !== -1)
            return 'shop.phasst.com'

        if (hostname.indexOf('peachysnaps.com') !== -1)
            return 'peachysnaps.com'

        return hostname
    }

    const plausible = Plausible({
        domain: reporting_domain()
    })
    
    plausible.enableAutoPageviews()
    
    return plausible;
}
