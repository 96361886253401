import {flattenIncludes, flattenObject} from './jsonApiHelpers'

// Get the products from the event's included objects
export const eventProducts = (eventData) => {
    if (eventData === undefined){
        return {event: undefined, products: undefined}
    }

    const {eventProductAssociations: products} = flattenIncludes(eventData)
    const {events: event} = flattenObject(eventData.data)

    return {event, products}
}
