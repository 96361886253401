import { useQuery } from "react-query";
import PhasstApi from './endpoints';
import { stringIsSearchable } from "../helpers/stringIsSearchable";

// Override the sensible defaults of react-query
// https://react-query.tanstack.com/docs/guides/important-defaults
const usePhasstDefaults = () => {
    const onError = (err) => {
        console.log(err)
    }

    return {
        cacheTime: 1000 * 60 * 60, // 1 hour
        staleTime: 1000 * 60 * 60 * 24, // 24 hours
        onError,
    }
}

const useEvent = (eventParams) => {
    return useQuery(["event", eventParams], PhasstApi.getEvent, usePhasstDefaults() );
}

const useEventKeywords = (event) => {
    return useQuery(["keywords", event], PhasstApi.getEventKeywords, Object.assign({enabled: event}, usePhasstDefaults()) );
}

const useEventPhotos = (event, photoParams, query) => {
    return useQuery(['eventPhotos', event, photoParams, query], PhasstApi.getEventPhotos, Object.assign({enabled: event}, usePhasstDefaults()))
}

const useFacePhotos = (event, face_id) => {
    return useQuery(['facePhotos', event, face_id], PhasstApi.getFacePhotos, Object.assign({enabled: event}, usePhasstDefaults()))
}

const useCompetitorData = (event, searchString) => {
    const searchable = stringIsSearchable(searchString) // While DebounceInput has a minLength, this also checks for non-numeric value
    return useQuery(['competitor', event, searchString], PhasstApi.getCompetitors, Object.assign({enabled: searchable}, usePhasstDefaults()));
}

const useOrder = (orderId) => {
    return useQuery(['order', orderId], PhasstApi.getOrder, usePhasstDefaults())
}

const useLineItemDownload = (orderId, lineItemId, enabled) => {
    return useQuery(['lineItem', orderId, lineItemId], PhasstApi.downloadLineItem, Object.assign({enabled}, usePhasstDefaults()));
}

export default {
    useEvent,
    useEventKeywords,
    useEventPhotos,
    useFacePhotos,
    useCompetitorData,
    useOrder,
    useLineItemDownload,
}
