import React from "react";
import {
  BrowserRouter,
  Switch,
  Route,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

import { GlobalStyle, defaultThemeFactory } from "./GlobalStyles"
import { CartProvider } from "./cart/cartContext";
import { EventProvider } from "./shop/eventContext";

import { ThemeProvider } from 'styled-components';
import { Suspense } from "react";
import { lazyWithRetry } from "./lazyWithRetry";

const Shop = lazyWithRetry(() => import('./shop/shop'));
const Order = lazyWithRetry(() => import('./order/order'));

function ErrorFallback({error, resetErrorBoundary}) {
  console.log({error})
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

export const defaultTheme = defaultThemeFactory('light')

export default function App() {
  const ThemedGlobalStyle = GlobalStyle(defaultTheme)
  return (
    <Sentry.ErrorBoundary FallbackComponent={ErrorFallback}>
    <ThemeProvider theme={defaultTheme}>
      <Suspense fallback={<div>Loading...</div>}>
      <ThemedGlobalStyle />
      <BrowserRouter>
        <Switch>

            <Route path="/order/:orderInvoice">
                <Order />
            </Route>

            <EventProvider>
              <CartProvider>

              <Route path="*">
                  <Shop />
              </Route>

              </CartProvider>
            </EventProvider>

        </Switch>
      </BrowserRouter>
      </Suspense>
    </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
}
