export const camelizeHyphens = (myString) => {
    // https://stackoverflow.com/questions/6660977/convert-hyphens-to-camel-case-camelcase#6661012
    return myString.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
}

export const flattenObject = (jsonApiObject) => {
    const obj = {id: jsonApiObject.id}
    const type = camelizeHyphens(jsonApiObject.type)
    for (const [key, value] of Object.entries(jsonApiObject.attributes)) {
        obj[camelizeHyphens(key)] = value
    }
    const response = {}
    response[type] = obj
    return response
}

export const flattenIncludes = (jsonApiObject) => {
    let includes = {}
    for (const include of jsonApiObject.included) {
        const obj = flattenObject(include)
        const objType = Object.keys(obj)[0]
        const objectValues = Object.values(obj)[0]
        if (includes[objType] === undefined)
        includes[objType] = [objectValues]
        else
            includes[objType].push(objectValues)
    }
    return includes
}