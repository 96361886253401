import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { init_plausible } from "./plausible";

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

init_plausible()

const sentry_dsn = process.env.REACT_APP_SENTRY_DSN;
const sentry_sample_rate = process.env.REACT_APP_SENTRY_SAMPLE_RATE || 1.0;
const sentry_release = process.env.REACT_APP_SENTRY_RELEASE || 'dev'

if (sentry_dsn !== undefined){
  Sentry.init({
    release: sentry_release,
    dsn: sentry_dsn,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: sentry_sample_rate,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
