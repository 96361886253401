// Extract the event name and domain from the URL
export const eventParams = (windowHostname) => {
    // Allow the test environment to override the URL
    let url = windowHostname
    if (windowHostname === 'localhost')
        url = process.env.REACT_APP_DOMAIN

    const domainArray = url.split('.');
    const eventTitle = domainArray.shift();
    const domain = domainArray.join('.');
    return {eventTitle, domain}
}
