import {useEffect, useState} from 'react';
import localforage from 'localforage';

// https://gist.github.com/blacksmoke26/af6c1b4c13cc99740285ab198d37fda4
// https://www.benmvp.com/blog/object-array-dependencies-react-useEffect-hook/

export const useLocalForage = (key, initialValue=[]) => {
	const [storedValue, setStoredValue] = useState(initialValue);
	
	useEffect(() => {
		(async function () {
			try {
				const value = await localforage.getItem(key);
                if (value)
				    setStoredValue(value);
			} catch ( err ) {
                console.log({err})
				return initialValue;
			}
		})();
	}, [storedValue.length]);

	const set = ( value ) => {
		(async function () {
			try {
				await localforage.setItem(key, value);
				setStoredValue(value);
			} catch (err) {
                console.log({err})
				return initialValue;
			}
		})();
	};
	
	return [storedValue, set];
}