import React, { createContext, useState, useEffect } from 'react';
import useCartIdCookie from './useCartIdCookie';
import { flattenCart, initialCartState } from './cartHelpers';
import { useLocalForage } from './useLocalForage';

const CartContext = createContext();

const CartProvider = (props) => {
    const [paymentRequest, setPaymentRequest] = useState(null);
    const {cartId, deleteCartIdCookie} = useCartIdCookie();

    const initialState = initialCartState()
    const [cartContents, setCartContents] = useState(initialState)
    const [localForageValue, setLocalForage] = useLocalForage(cartId);

    useEffect(() => {
      const flatCart = flattenCart(localForageValue)
      setCartContents(flatCart)
    }, [localForageValue.length])

    return (
      <CartContext.Provider value={{
        cartContents, cartId, deleteCartIdCookie,
        paymentRequest, setPaymentRequest,
        localForageValue, setLocalForage,
        }}>
        {props.children}
      </CartContext.Provider>
    )
  }

export {CartContext, CartProvider}
