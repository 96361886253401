import { v4 as uuidv4 } from 'uuid';

const keyName = 'phasstCartId'
const defaultMaxAge = 60 * 60 * 24 * 30 // 30 days

// This is exempt from the cookie law as it is purely used for technical provision of the service.

const deleteCartIdCookie = () => {
    const domain = allowSubdomains()
    setCookie({value: undefined, domain, maxAge: 0})
}

const useCartIdCookie = () => {
    const existingId = getCartIdFromCookie()
    if (existingId)
        return {cartId: existingId, deleteCartIdCookie}

    const cartId = setNewCartIdCookie()
    return {cartId, deleteCartIdCookie};
};
export default useCartIdCookie;

const setNewCartIdCookie = () => {
    const cartId = uuidv4();
    const safeValue = encodeURIComponent(cartId)
    const domain = allowSubdomains()

    setCookie({value: safeValue, domain, maxAge: defaultMaxAge})

    return cartId
}

const setCookie = ({value, domain, maxAge}) => {
    const cookieParams = [
        `${keyName}=${value}`,
        'path=/',
        `domain=${domain}`,
        `max-age=${maxAge}`,
    ]
    const cookie = cookieParams.join('; ')
    document.cookie = cookie
}

const allowSubdomains = () => {
    // converts foo.example.com to .example.com
    // to allow for subdomains to share this cookie
    const domainParts = window.location.hostname.split('.')
    const noSub = domainParts.slice(1).join('.')
    return `.${noSub}`
}

const getCartIdFromCookie = () => {
    const cookieParts = document.cookie.split('; ')
    for (const cookie of cookieParts) {
        const [key, value] = cookie.split("=")
        if (key === keyName)
            return decodeURIComponent(value)
    }
    return null
}