import React, { createContext, useEffect, useState, useContext } from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { ThemeContext } from 'styled-components';
import ApiHooks from '../api/hooks'
import {eventParams} from '../helpers/eventParams';
import {eventProducts} from '../helpers/eventProducts';
import { useErrorHandler } from 'react-error-boundary';

const EventContext = createContext();

const EventProvider = (props) => {
    const themeContext = useContext(ThemeContext);

    const eventSearchParams = eventParams(window.location.hostname)
    const { data: eventData, isLoading: eventIsLoading, error: eventLoadError } = ApiHooks.useEvent(eventSearchParams)
    useErrorHandler(eventLoadError)
    const {event, products} = eventProducts(eventData)
    const { data: keywords, isLoading: keywordsAreLoading } = ApiHooks.useEventKeywords(event)

    const [stripePromise, setstripePromise] = useState();

    useEffect(() => {
      if (eventData !== undefined){ 
        const {stripeConnectedAccountId: stripeAccount, stripePublishableKey} = event
        loadStripe(stripePublishableKey, {stripeAccount}).then((result, error) => {
          if (error)
            console.log({error})

          setstripePromise(result)
        })
      }
    // The `event` object is a transformation of the `eventData`, which comes from the API request.
    // The transformation of the data should not re-render, only changes in the `eventData` should re-run useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventData])

    if (stripePromise === undefined) {
      return (
        <EventContext.Provider value={{event, eventIsLoading, eventLoadError, keywords, keywordsAreLoading, products, stripePromise}}>
            {props.children}
        </EventContext.Provider>
      )
    }

    return (
      <EventContext.Provider value={{event, eventIsLoading, eventLoadError, keywords, keywordsAreLoading, products, stripePromise}}>
          <Elements stripe={stripePromise}>
            {props.children}
          </Elements>
      </EventContext.Provider>
    )
  }

export {EventContext, EventProvider}
