const flatCartKey = (photo, product) => {
    return [product.id, photo.id].join('-')
}

const flattenCart = (cartItems) => {
    const flatCart = initialCartState()
    for (const cartItem of cartItems) {
        const {photo, product, quantity} = cartItem
        const productPhotoKey = flatCartKey(photo, product)

        if (productPhotoKey in flatCart.items) {
            flatCart.items[productPhotoKey].quantity += quantity
        } else {
            flatCart.items[productPhotoKey] = {...cartItem}
        }
    }
    flatCart.summary = cartSums(flatCart)
    return flatCart;
}

const itemQuantity = (flatCart, photo, product) => {
    const productPhotoKey = flatCartKey(photo, product)
    if (productPhotoKey in flatCart.items) {
        return flatCart.items[productPhotoKey].quantity
    } else {
        return 0
    }
}

const cartSums = (flatCart) => {
    let itemCount = 0
    let grossValue = 0.0
    let netValue = 0.0
    let discount = 0.0
    for (const cartItem of Object.values(flatCart.items)) {
        if (cartItem.quantity > 0){
            itemCount += cartItem.quantity
            grossValue += cartItem.quantity * cartItem.product.price
        }
    }
    if (grossValue > 30) {
        discount = grossValue * 0.25
    }
    netValue = parseFloat((grossValue - discount).toFixed(2));
    return {itemCount, grossValue, discount, netValue}
}

const initialCartState = () => {
    return {
        items: {},
        summary: {
          grossValue: 0,
          itemCount: 0,
          discount: 0,
          netValue: 0
        }
    }
}

export {flattenCart, itemQuantity, cartSums, initialCartState}
