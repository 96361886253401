import axios from 'axios';

const hostname = process.env.REACT_APP_API_HOST || 'https://api-cached.phasst.com';
axios.defaults.baseURL = `${hostname}/api/v1`;
axios.defaults.headers.common['Authorization'] = 'Bearer 1234';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/vnd.api+json';

function getEventId(event) {
    return event.id;
}

const getEvent = async (_, eventParams) => {
    const {eventTitle, domain} = eventParams
    const {data} = await axios.get(`/events/${eventTitle}`, {params: {domain}})
    return data;
}

const getEventKeywords = async (_, event) => {
    const eventId = getEventId(event);
    const {data}  = await axios.get(`/events/${eventId}/keywords`)
    return data;
}

const getEventPhotos = async (_, event, photoParams, query) => {
    const eventId = getEventId(event);
    let url = `/events/${eventId}/uploaded_photos`;

    const {keyword_id, race_number} = photoParams;
    if (keyword_id)
        url = `/events/${eventId}/keywords/${keyword_id}/uploaded_photos`
    if (race_number)
        url = `/events/${eventId}/competitors/${race_number}/uploaded_photos`
    
    const {data}  = await axios.get(url, {params: query})
    return data;
}

const getFacePhotos = async (_, event, face_id) => {
    const eventId = getEventId(event);
    const {data}  = await axios.get(`/events/${eventId}/face_search/${face_id}`)
    return data;
}

const getCompetitors = async (_, event, searchString) => {
    const eventId = getEventId(event);
    const {data}  = await axios.get(`/events/${eventId}/competitors/search?name=${searchString}`)
    return data;
}

const postCharge = async (chargeBody) => {
    const {data}  = await axios.post('/charges', chargeBody)
    return data;
}

const postFaceSearch = async({eventId, base64, onUploadProgress}) => {
    let formData = new FormData();
    formData.append('image', base64);
    const {data} = await axios.post(`/events/${eventId}/face_search`, formData, {
        headers: {
            "Content-type": "multipart/form-data",
        },
        onUploadProgress
    })
    return data;
}

const putCharge = async (chargeBody) => {
    const {data}  = await axios.put(`/charges/${chargeBody.data.id}`, chargeBody)
    return data;
}

const getOrder = async (_, orderId) => {
    const {data}  = await axios.get(`/orders/${orderId}`)
    return data;
}

const downloadLineItem = async (_, orderId, lineItemId) => {
    const {data}  = await axios.get(`/orders/${orderId}/line_items/${lineItemId}/download`, {responseType: 'blob'} )
    return data;
}

export default {
    getEvent,
    getEventKeywords,
    getEventPhotos,
    getFacePhotos,
    getCompetitors,
    postCharge,
    putCharge,
    getOrder,
    downloadLineItem,
    postFaceSearch,
}
