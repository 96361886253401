import { createGlobalStyle } from "styled-components"
import "./font/roboto.css"

export const defaultThemeFactory = (mode='light') => {
  
  // https://blog.agney.dev/theming-on-styled-components/
  const backgrounds = {
    'dark': [
        '#000000',
        '#262626',
        '#5C5C5C',
        '#D9D9D9',
        '#ffffff',
    ],
    'light': [
        '#ffffff',
        '#F2F2F2',
        '#D9D9D9',
        '#262626',
        '#000000',
    ]
  }

  const bgs = backgrounds[mode]
  return (
    {
      name: mode,
      primaryBgColor: '#B1B1B1',
      secondaryBgColor: '#CC0000',
      primaryTextColor: 'black',
      secondaryTextColor: 'white',
      defaultTextColor: bgs[4],
      backgrounds: bgs,
    }
  )
}

export const GlobalStyle = (theme, showBodyBg=true) =>{
  let bodyBg = theme.backgrounds[1];
  if (!(showBodyBg)){
    // Purely so the storybooks can have a transparent background
    bodyBg = 'none'
  }
  return (
    createGlobalStyle`
      html {
        padding: 0;
        margin: 0;
      }
      body {
        font-family: 'Roboto Regular';
        font-size: 62.5%;
        color: ${theme.defaultTextColor};
        background: ${bodyBg};
        padding: 0;
        margin: 0;
      }

      h1 {
        font-family: 'Roboto Black Italic';
        text-rendering: geometricPrecision;
        font-size: 1.6em;
        letter-spacing: 0.1px;
        line-height: 1.2em;
      }

      h2 {
        font-family: 'Roboto Black Italic';
        font-size: 1.3em;
        letter-spacing: 0.1px;
        line-height: 1.5em;
      }

      h3 {
        font-family: 'Roboto Black Italic';
        font-size: 1.1em;
        letter-spacing: 0.3px;
        line-height: 1.5em;
      }

      button {
        font-family: 'Roboto Black';
      }

      p, a {
        color: ${theme.defaultTextColor};
        font-family: 'Roboto Italic';
        font-size: 1em;
        font-weight: 600;
        letter-spacing: 0.5px;
        line-height: 1.5em;
      }
    `
  )
}
